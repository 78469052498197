










































import Vue from 'vue'
import Loading from '@/components/Loading/index.vue'
import Search from '@/components/Search/Search.vue'
import ChatContact from '@/components/Chats/ChatContact.vue'
import ChatMessages from '@/components/Chats/ChatMessages.vue'
import { mapActions, mapGetters } from 'vuex'
// import { Centrifuge } from 'centrifuge'

const CHATS_FETCH_PAGINATION_NUMBER = 20

export default Vue.extend({
  name: 'Chats',

  components: {
    Loading,
    Search,
    ChatContact,
    ChatMessages
  },

  data () {
    return {
      loading: false,
      searchValue: '',
      chosenContact: null,
      chatsFetchLimit: CHATS_FETCH_PAGINATION_NUMBER,
      chatsFetchOffset: 0,
      chatsListLoading: false,
      allowFetching: true
    }
  },

  computed: {
    ...mapGetters({
      modifiedChatsList: 'chats/modifiedChatsList'
    }),

    filteredChatsList () {
      return this.modifiedChatsList.filter(c => c?.users[0].username.includes(this.searchValue))
    }
  },

  methods: {
    ...mapActions({
      getChatsList: 'chats/getChatsList',
      sendMessageChatById: 'chats/sendMessageChatById',
      fetchMessagesChatById: 'chats/fetchMessagesChatById',
      fetchUnreadCountChats: 'chats/fetchUnreadCountChats'
    }),

    clearSearchValue () {

    },

    async onClickChooseContact (chat) {
      await this.fetchMessagesChatById(chat.id)
      // setInterval(() => {
      //   if (!document.hidden) {
      //     this.fetchMessagesChatById(chat.id)
      //   }
      // }, 60000)
      this.$router.push({
        query: {
          ...this.$route.query,
          chat: chat.id,
          username: chat.users[0].username
        }
      })
      this.chosenContact = chat.id
      // this.fetchUnreadCountChats()
    },

    async fetchChatsList () {
      const res = await this.getChatsList({
        limit: this.chatsFetchLimit,
        offset: this.chatsFetchOffset
      })
      this.$router.push({
        query: {
          ...this.$route.query,
          offset: this.chatsFetchOffset
        }
      })
      return res
    }
  },

  async mounted () {
    this.loading = true
    this.fetchUnreadCountChats()
    if ('offset' in this.$route.query) {
      const requests = []
      for (let i = 0; i <= this.$route.query.offset; i += CHATS_FETCH_PAGINATION_NUMBER) {
        this.chatsFetchOffset = i
        requests.push(
          this.fetchChatsList()
        )
      }
      await Promise.all(requests)
    } else {
      await this.fetchChatsList()
    }

    this.chosenContact = this.$route.query.chat || null
    if (this.chosenContact) {
      await this.fetchMessagesChatById(this.chosenContact)
      // setInterval(() => {
      // if (!document.hidden) {
      // this.fetchMessagesChatById(this.chosenContact)
      // }
      // }, 60000)
      // addEventListener('focus', () => this.fetchMessagesChatById(this.chosenContact))
    }
    this.loading = false

    this.$nextTick(() => {
      const element = this.$refs.chatsListRef
      element.addEventListener('scroll', e => {
        const scrolledToBottom = Math.ceil(e.target.scrollTop) + 100 >= e.target.scrollHeight - e.target.offsetHeight
        if (scrolledToBottom && this.allowFetching) {
          this.chatsListLoading = true
          this.chatsFetchOffset = this.chatsFetchOffset + CHATS_FETCH_PAGINATION_NUMBER
          this.fetchChatsList().then((res) => {
            this.chatsListLoading = false
            if (!res.length) this.allowFetching = false
          })
        }
      })
    })
  }
})
