

































































































































import Vue from 'vue'
import Loading from '@/components/Loading/index.vue'
import { mapState, mapActions } from 'vuex'
import { cloneDeep, isEqual } from 'lodash'

export default Vue.extend({

  name: 'LongDelivery',

  components: {
    Loading
  },

  data () {
    return {
      loading: false,
      saving: false,
      localDelivery: {
        min_order_price: 0,
        delivery_price: 0,
        delivery_time: 0,
        radius: 0
      }
    }
  },

  computed: {
    ...mapState('longDelivery', [
      'long_delivery'
    ]),

    isPrevStateEqualCurrent () {
      return isEqual(this.localDelivery, this.long_delivery)
    }
  },

  methods: {
    ...mapActions('longDelivery', ['getLongDelivery', 'editLongDeliveryParams']),

    async onSubmit () {
      this.saving = true
      try {
        await this.editLongDeliveryParams(this.localDelivery)
        this.$toast.success('Данные обновлены')
      } catch (error) {
        console.error(error)
      } finally {
        this.saving = false
      }
    },

    onCancel () {
      this.localDelivery = cloneDeep(this.long_delivery)
    }
  },

  async mounted () {
    this.loading = true
    await this.getLongDelivery()
    this.localDelivery = cloneDeep(this.long_delivery)
    this.loading = false
  }

})
