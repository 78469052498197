


















































































































import Vue from 'vue'
import Loading from '@/components/Loading/index.vue'
import Search from '@/components/Search/Search.vue'
import DatePicker from 'vue2-datepicker'
import { mapState, mapGetters, mapActions } from 'vuex'

export default Vue.extend({
  name: 'PerimetrOrders',

  components: {
    Loading,
    Search,
    DatePicker
  },

  data () {
    return {
      loading: false,
      report: null,
      initialLoadingTabClick: false,
      searchValue: '',
      selectedSearchMethod: 'order_no',
      fetchParams: {
        active: true,
        offset: 0,
        limit: 10,
        sort_type: true,
        sort_by: 'created_at_timestamp'
      },
      page: 1,
      reportDateValue: null,
      isModalShown: false
    }
  },

  computed: {
    ...mapState('orders', ['ordersAdministrationCount']),
    ...mapGetters('orders', ['serializedOrdersAdministration']),

    componentToShow () {
      switch (Boolean(this.$route.query.isFinished)) {
        case false:
          return () => import('@/components/Orders/Current.vue')

        case true:
          return () => import('@/components/Orders/Archived.vue')

        default:
          return () => import('@/components/Orders/Current.vue')
      }
    },

    offsetQuery () {
      if (!this.$route.query.offset) return null
      return { offset: this.$route.query.offset }
    },

    placeholderSearch () {
      return this.selectedSearchMethod === 'order_no' ? 'Введите номер заказа' : 'Введите логин'
    }
  },

  methods: {
    ...mapActions({
      getOrdersAdministration: 'orders/getOrdersAdministration',
      getOrderOperators: 'orders/getOrderOperators',
      createOrdersReport: 'orders/createOrdersReport'
    }),

    changeTab (tabNumber) {
      this.page = tabNumber
      this.fetchParams.offset = (this.page - 1) * this.fetchParams.limit
      this.setRouteQuery('page', this.page)
      this.setRouteQuery('offset', this.fetchParams.offset)
    },

    setRouteQuery (paramName: string, paramValue: string) {
      const query = { ...this.$route.query, [paramName]: paramValue }

      this.$router.push({ query }).catch(error => {
        if (error.name !== 'NavigationDuplicated') {
          throw error
        }
      })
    },

    sortingChanged ({ sortBy, sortDesc }) {
      this.setRouteQuery('sort_by', sortBy)
      this.setRouteQuery('sort_type', sortDesc)
      this.setRouteQuery('page', 1)
    },

    async handleSearch () {
      if (!this.searchValue.trim()) {
        this.$refs['search-input'].$el.focus()
        return
      }
      this.fetchParams.offset = 0
      this.setRouteQuery('search', this.searchValue.trim())
      this.setRouteQuery('search_type', this.selectedSearchMethod)
    },

    async clearSearchValue () {
      this.fetchParams.offset = 0
      this.searchValue = ''
    },

    async getReports () {
      this.loading = true
      const [fromDate, toDate] = this.reportDateValue
      this.report = await this.createOrdersReport({
        from_date: fromDate.split('.').reverse().join('-'),
        to_date: toDate.split('.').reverse().join('-')
      })
      this.loading = false
      this.isModalShown = true
    },

    handleClickDownloadReport () {
      window.open(this.report, '_blank')
      this.isModalShown = false
      this.report = null
    }
  },

  mounted () {
    this.getOrderOperators()
  },

  watch: {
    $route: {
      async handler (value) {
        this.loading = true
        const { query } = value
        this.searchValue = query.search || ''
        this.fetchParams.offset = Number(query.offset || 0)
        this.selectedSearchMethod = query.search_type || 'order_no'
        this.fetchParams.sort_type = query?.sort_type || true
        this.fetchParams.sort_by = query?.sort_by || 'created_at_timestamp'
        if (query.search) {
          if (this.selectedSearchMethod === 'order_no') {
            this.fetchParams.order_no = query.search
            this.fetchParams.bp_login = null
          } else if (this.selectedSearchMethod === 'bp_login') {
            this.fetchParams.bp_login = query.search
            this.fetchParams.order_no = null
          }
        } else {
          this.fetchParams.bp_login = null
          this.fetchParams.order_no = null
        }
        this.fetchParams.active = !query.isFinished
        this.page = +value.query.page || 1
        await this.getOrdersAdministration(this.fetchParams)
        this.loading = false
        this.initialLoadingTabClick = false
      },
      immediate: true,
      deep: true
    },

    searchValue (value) {
      if (!value.length && this.$route.query?.search?.length) {
        const isFinishedQuery = 'isFinished' in this.$route.query
        this.$router.push({
          name: this.$route.name,
          query: {
            search_type: this.selectedSearchMethod,
            ...(isFinishedQuery ? { isFinished: true } : null)
          }
        })
      }
    }
  }
})
