














import Vue from 'vue'
export default Vue.extend({

  name: 'ChatContact',

  props: {
    chat: {
      type: Object,
      required: true
    }
  },

  computed: {
    chatUserData () {
      return this.chat?.users[0]
    },

    lastMessageData () {
      const isMsgDateToday = this.$moment(this.chat?.last_message.created_at).isSame(new Date(), 'day')
      const msgDate = isMsgDateToday
        ? `Сегодня, ${this.$moment(this.chat?.last_message.created_at).format('HH:mm')}`
        : `${this.$moment(this.chat?.last_message.created_at).format('DD.MM.YY, HH:mm')}`
      return {
        ...this.chat?.last_message,
        msg_date: msgDate
      }
    }
  }

})
