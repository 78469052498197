




































































































import Vue from 'vue'
import Loading from '@/components/Loading/index.vue'
import EmojiPicker from 'vue-emoji-picker'
import { mapGetters, mapActions } from 'vuex'
import { groupBy, cloneDeep } from 'lodash'

const ITEMS_SORT_BY = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря', 'Сегодня']

export default Vue.extend({
  name: 'ChatMessages',

  components: {
    Loading,
    EmojiPicker
  },

  data () {
    return {
      message: '',
      file: null,
      loading: false,
      initialLoading: false,
      previewImage: null,
      localMessages: []
    }
  },

  computed: {
    ...mapGetters({
      chatMessagesById: 'chats/chatMessagesById'
    }),

    currentChatMessages () {
      const data = this.localMessages.map(c => {
        const isMsgDateToday = c.created_at ? this.$moment(c.created_at).isSame(new Date(), 'day') : true
        const createdAtFull = isMsgDateToday
          ? 'Сегодня'
          : this.$moment(c?.created_at).format('D MMMM')
        return {
          ...c,
          createdAtFull
        }
      })

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return Object.entries(groupBy(data, 'createdAtFull')).sort((a, b) => {
        if (a[0] === 'Сегодня') {
          return false
        } else {
          return ITEMS_SORT_BY.indexOf(a[0].split(' ')[1]) - ITEMS_SORT_BY.indexOf(b[0].split(' ')[1])
        }
      })
    },

    currentChatUser () {
      return this.$route.query.username
    }
  },

  methods: {
    ...mapActions({
      sendMessageChatById: 'chats/sendMessageChatById',
      uploadMedia: 'common/uploadMedia'
    }),

    insertEmoji (emoji) {
      this.message += emoji
    },

    onClickPreviewImg (url) {
      this.previewImage = url
      this.$bvModal.show('modal-preview-image')
    },

    async handleFileUpload () {
      this.file = URL.createObjectURL(this.$refs.file.files[0])
    },

    onClickOpenUpload () {
      this.$refs.file.click()
    },

    async onClickSendMsg () {
      let payload = {}

      if (this.file) {
        this.loading = true
        const formData = new FormData()
        formData.append('file_to_upload', this.$refs.file.files[0])
        const res = await this.uploadMedia(formData)
        payload = {
          text: '',
          type: 'media',
          attachment: {
            media_url: res.url
          }
        }
      }

      if (this.message) {
        payload = {
          text: this.message,
          type: 'text'
        }
      }

      await this.sendMessageChatById({
        id: this.$route.query.chat,
        payload: payload
      })
      this.localMessages.push(payload)
      setTimeout(() => {
        this.$refs.chatContent.scrollTo({ top: this.$refs.chatContent.scrollHeight, behavior: 'smooth' })
      }, 100)
      this.message = ''
      this.file = null
      this.loading = false
    }
  },

  watch: {
    $route: {
      handler () {
        this.initialLoading = true
        this.localMessages = cloneDeep(this.chatMessagesById(this.$route.query.chat))
        setTimeout(() => {
          this.$refs.chatContent.scrollTo({ top: this.$refs.chatContent.scrollHeight })
          this.initialLoading = false
        }, 100)
      },
      immediate: true
    }
  },

  mounted () {
    // setInterval(() => {
    // if (!document.hidden) {
    this.localMessages = cloneDeep(this.chatMessagesById(this.$route.query.chat))
    //   }
    // }, 60000)

    // addEventListener('focus', () => {
    //   this.localMessages = cloneDeep(this.chatMessagesById(this.$route.query.chat))
    // })
  }
})
