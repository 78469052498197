var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',[_vm._v(_vm._s(_vm.$route.meta.title))]),(_vm.loading)?_c('Loading',{attrs:{"isFixed":true}}):_vm._e(),_c('div',{staticClass:"content__inner"},[(!_vm.loading)?_c('b-form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{ref:"validationProvider",attrs:{"name":"Радиус бесплатной доставки, км","rules":{ required: true, min_value: 0, max: 5}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-cols-sm":"6","label":"Радиус бесплатной доставки, км"}},[_c('b-form-input',{attrs:{"state":errors[0] ? false : null,"type":"number","aria-describedby":"price-box-feedback"},model:{value:(_vm.localDelivery.radius),callback:function ($$v) {_vm.$set(_vm.localDelivery, "radius", _vm._n($$v))},expression:"localDelivery.radius"}}),_c('b-form-invalid-feedback',{attrs:{"id":"price-box-feedback"}},_vm._l((errors),function(error,index){return _c('span',{key:index},[_vm._v(_vm._s(error))])}),0)],1)]}}],null,false,3052702996)}),_c('ValidationProvider',{ref:"validationProvider",attrs:{"name":"Минимальная сумма заказа, KGS","rules":{ required: true, min_value: 0, max: 20 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-cols-sm":"6","label":"Минимальная сумма заказа, KGS"}},[_c('b-form-input',{attrs:{"state":errors[0] ? false : null,"type":"number","aria-describedby":"price-box-feedback"},model:{value:(_vm.localDelivery.min_order_price),callback:function ($$v) {_vm.$set(_vm.localDelivery, "min_order_price", _vm._n($$v))},expression:"localDelivery.min_order_price"}}),_c('b-form-invalid-feedback',{attrs:{"id":"price-box-feedback"}},_vm._l((errors),function(error,index){return _c('span',{key:index},[_vm._v(_vm._s(error))])}),0)],1)]}}],null,false,1052180610)}),_c('ValidationProvider',{ref:"validationProvider",attrs:{"name":"Добавочное время доставки, мин","rules":{ required: true, min_value: 0, max: 3 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-cols-sm":"6","label":"Добавочное время доставки, мин"}},[_c('b-form-input',{attrs:{"state":errors[0] ? false : null,"type":"number","aria-describedby":"price-box-feedback"},model:{value:(_vm.localDelivery.delivery_time),callback:function ($$v) {_vm.$set(_vm.localDelivery, "delivery_time", _vm._n($$v))},expression:"localDelivery.delivery_time"}}),_c('b-form-invalid-feedback',{attrs:{"id":"price-box-feedback"}},_vm._l((errors),function(error,index){return _c('span',{key:index},[_vm._v(_vm._s(error))])}),0)],1)]}}],null,false,3698547184)}),_c('ValidationProvider',{ref:"validationProvider",attrs:{"name":"Стоимость платной доставки, KGS","rules":{ required: true, min_value: 0, max: 20 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-cols-sm":"6","label":"Стоимость платной доставки, KGS"}},[_c('b-form-input',{attrs:{"state":errors[0] ? false : null,"type":"number","aria-describedby":"price-box-feedback"},model:{value:(_vm.localDelivery.delivery_price),callback:function ($$v) {_vm.$set(_vm.localDelivery, "delivery_price", _vm._n($$v))},expression:"localDelivery.delivery_price"}}),_c('b-form-invalid-feedback',{attrs:{"id":"price-box-feedback"}},_vm._l((errors),function(error,index){return _c('span',{key:index},[_vm._v(_vm._s(error))])}),0)],1)]}}],null,false,1189080392)}),_c('b-button',{staticClass:"mr-3",attrs:{"disabled":_vm.saving,"variant":"primary","type":"submit"}},[_vm._v("Сохранить")]),_c('b-button',{attrs:{"disabled":_vm.isPrevStateEqualCurrent,"variant":"secondary"},on:{"click":_vm.onCancel}},[_vm._v("Отменить")])],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }