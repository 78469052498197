








import Vue from 'vue'
import Promocodes from '@/components/Promocodes/index.vue'

export default Vue.extend({
  name: 'PerimetrPromocodes',

  components: {
    Promocodes
  }
})
